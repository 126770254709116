<template>
  <v-app id="app">
    <app-nav-drawer v-show="showNavDrawer" :toggleDrawer="showDrawer"></app-nav-drawer>
    <app-navbar v-show="showNavDrawer" @toggleDrawer="toggleDrawer" @closeDrawer="closeDrawer"></app-navbar>
    <v-snackbar multi-line timeout="-1" top right v-model="showUpdateNotification">
      <v-row>
        <v-col cols="12" class="text-h6"> Es gibt ein neues Update. Bitte aktualisieren Sie die Seite. </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-btn color="pink" text @click="showUpdateNotification = false"> Ignorieren </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="refreshApplication"> Aktualisieren </v-btn>
      </v-row>
    </v-snackbar>
    <v-main class="px-0">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <v-btn
        v-if="isHome"
        fab
        icon
        right
        color="white"
        style="background-color: #bfbfbf"
        absolute
        bottom
        elevation="2"
        class="mb-16"
        large
        to="/login"
        ><v-icon>mdi-login</v-icon></v-btn
      >
    </v-main>
    <app-footer v-show="showNavDrawer"></app-footer>
    <prompt-dialog></prompt-dialog>
    <snackbar></snackbar>
  </v-app>
</template>

<style scoped>
#app.theme--light {
  background: #595959;
}
</style>

<script lang="ts" src="./app.component.ts"></script>
