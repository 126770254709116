<template>
  <v-navigation-drawer v-model="drawer" app clipped absolute temporary>
    <v-list-item class="mt-16">
      <v-list-item-content>
        <v-list-item-title class="text-h5">{{ username }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item to="/customer" v-if="hasAdminAuthority || hasMarketerAuthority">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Kunden</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/archived-customer" v-if="hasAdminAuthority">
        <v-list-item-icon>
          <v-icon>mdi-account-off</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Archivierte Kunden</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/calendar" v-if="hasAdminAuthority || hasMarketerAuthority">
        <v-list-item-icon>
          <v-icon>mdi-calendar-text</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Kalender</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/estimate" v-if="hasAdminAuthority || hasMarketerAuthority">
        <v-list-item-icon>
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Estimating</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/system-analyse" v-if="hasAdminAuthority || hasMarketerAuthority">
        <v-list-item-icon>
          <v-icon>mdi-chart-areaspline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>System analyse</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card flat :color="dataGroupCardColor" v-if="hasAdminAuthority || hasMarketerAuthority">
        <v-list-group v-model="marketerParameterGroup" prepend-icon="mdi-account-tie-voice">
          <template v-slot:activator>
            <v-list-item-title>Berater Parameter</v-list-item-title>
          </template>
          <v-list-item-group>
            <v-list-item to="/default-display-value">
              <v-list-item-icon>
                <v-icon>mdi-monitor</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Wertanzeige</v-list-item-title>
            </v-list-item>
            <v-list-item to="/model-a-parameter">
              <v-list-item-icon>
                <v-icon>mdi-alpha-a-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Modell A</v-list-item-title>
            </v-list-item>
            <v-list-item to="/model-c-parameter">
              <v-list-item-icon>
                <v-icon>mdi-alpha-c-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Modell C</v-list-item-title>
            </v-list-item>
            <v-list-item to="/model-d-parameter">
              <v-list-item-icon>
                <v-icon>mdi-alpha-d-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Modell D</v-list-item-title>
            </v-list-item>
            <v-list-item to="/model-e-parameter">
              <v-list-item-icon>
                <v-icon>mdi-alpha-e-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Modell E</v-list-item-title>
            </v-list-item>
            <v-list-item to="/model-f-parameter">
              <v-list-item-icon>
                <v-icon>mdi-alpha-f-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Modell F</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
      </v-card>
      <v-card flat :color="dataGroupCardColor" v-if="hasAdminAuthority">
        <v-list-group v-model="systemParameterGroup" prepend-icon="mdi-tune">
          <template v-slot:activator>
            <v-list-item-title>System Parameter</v-list-item-title>
          </template>
          <v-list-item-group>
            <v-list-item to="/digital-values">
              <v-list-item-icon>
                <v-icon color="warning">mdi-weight</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Digitalwert</v-list-item-title>
            </v-list-item>
            <v-list-item to="/chance-weight">
              <v-list-item-icon>
                <v-icon color="warning">mdi-cash-100</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Chance Gewicht</v-list-item-title>
            </v-list-item>
            <v-list-item to="/age">
              <v-list-item-icon>
                <v-icon color="warning">mdi-tag-faces</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Altersgrenzen</v-list-item-title>
            </v-list-item>
            <v-list-item to="/offer-type">
              <v-list-item-icon>
                <v-icon color="warning">mdi-format-list-checks</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Angebotstyp</v-list-item-title>
            </v-list-item>
            <v-list-item to="/life-expectancy">
              <v-list-item-icon>
                <v-icon color="warning">mdi-account-heart</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Lebenserwartung</v-list-item-title>
            </v-list-item>
            <v-list-item to="/guaranteed-values">
              <v-list-item-icon>
                <v-icon color="warning">mdi-home-thermometer</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Garantiewerte</v-list-item-title>
            </v-list-item>
            <v-list-item to="/live-duration">
              <v-list-item-icon>
                <v-icon color="warning">mdi-one-up</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Dauer des Wohnens</v-list-item-title>
            </v-list-item>
            <v-list-item to="/model-a-system-parameter">
              <v-list-item-icon>
                <v-icon color="warning">mdi-alpha-a-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Modell A System Parameter</v-list-item-title>
            </v-list-item>
            <v-list-item to="/model-c-system-parameter">
              <v-list-item-icon>
                <v-icon color="warning">mdi-alpha-c-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Modell C System Parameter</v-list-item-title>
            </v-list-item>
            <v-list-item to="/model-e-system-parameter">
              <v-list-item-icon>
                <v-icon color="warning">mdi-alpha-e-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Modell E System Parameter</v-list-item-title>
            </v-list-item>
            <v-list-item to="/deviation">
              <v-list-item-icon>
                <v-icon color="warning">mdi-alpha-a-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Abweichungen</v-list-item-title>
            </v-list-item>
            <v-list-item to="/special-limits">
              <v-list-item-icon>
                <v-icon color="warning">mdi-alpha-a-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sonderfälle</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
        <v-list-group prepend-icon="mdi-cog-outline">
          <template v-slot:activator>
            <v-list-item-title>Verwaltung</v-list-item-title>
          </template>
          <v-list-item-group>
            <v-list-item to="/admin/user-management">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Benutzer verwalten</v-list-item-title>
            </v-list-item>
            <v-list-item to="/system-emails">
              <v-list-item-icon>
                <v-icon>mdi-email-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>System Mail-Adressen</v-list-item-title>
            </v-list-item>
            <v-list-item to="/notaries" v-if="hasAdminAuthority">
              <v-list-item-icon>
                <v-icon>mdi-book-open-page-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Notare</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/value-sources">
              <v-list-item-icon>
                <v-icon>mdi-more</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Quelle ImmoWert</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/value-Declarer">
              <v-list-item-icon>
                <v-icon>mdi-cash-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Werteingaben</v-list-item-title>
            </v-list-item>
            <v-list-item to="/province">
              <v-list-item-icon>
                <v-icon>mdi-map-marker-radius-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Bundesland</v-list-item-title>
            </v-list-item>
            <v-list-item to="/marital-status">
              <v-list-item-icon>
                <v-icon>mdi-account-heart</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Familienstand</v-list-item-title>
            </v-list-item>
            <v-list-item to="/document-type">
              <v-list-item-icon>
                <v-icon>mdi-file-document</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Unterlagentypen</v-list-item-title>
            </v-list-item>
            <v-list-item to="/customers-type">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Kundentypen</v-list-item-title>
            </v-list-item>
            <v-list-item to="/lead-sources">
              <v-list-item-icon>
                <v-icon>mdi-source-branch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Quelle1</v-list-item-title>
            </v-list-item>
            <v-list-item to="/second-lead-sources">
              <v-list-item-icon>
                <v-icon>mdi-source-branch</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Quelle2</v-list-item-title>
            </v-list-item>
            <v-list-item to="/marketing-actions">
              <v-list-item-icon>
                <v-icon>mdi-arrow-expand-all</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Marketing Actions</v-list-item-title>
            </v-list-item>
            <v-list-item to="/sales-areas">
              <v-list-item-icon>
                <v-icon>mdi-salesforce</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sales Areas</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
      </v-card>
      <v-list-item to="/lead-parameters" v-if="hasAdminAuthority || hasMarketerAuthority">
        <v-list-item-icon>
          <v-icon>mdi-web</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Leadgenerator Parameter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append v-if="authenticated">
      <div class="pa-2" @click="logout">
        <v-btn block><v-icon>mdi-logout</v-icon> Ausloggen </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts" src="./app-nav-drawer.component.ts"></script>
<style>
.theme--light.v-navigation-drawer {
  position: fixed;
}
.theme--dark.v-navigation-drawer {
  position: fixed;
}
</style>
